import { useLocation, useSearchParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "../store";
import { addDrawerItem, removeDrawerItem } from "../slices/drawer-stack.slice";

export enum Params {
  CREATE_TASK_PARAM = "CREATE_TASK",
  TASK_ID_SEARCH_PARAM_NAME = "TASK_ID",
  TASK_LINK_ID_URL_PARAM = "LINK_ID",
  TASK_LINK_TYPE_URL_PARAM = "LINK_TYPE",
  SELECTED_TAB = "SELECTED_TAB",
  MANUAL_CHECKIN_STEP = "MANUAL_CHECKIN_STEP",
  SELECTED_MANUAL_CHECKIN_RESERVATION_ID = "SELECTED_MANUAL_CHECKIN_RESERVATION_ID",
  SELECTED_RESERVATION_ID = "SELECTED_RESERVATION_ID",
  SELECTED_CONVERSATION_ID = "SELECTED_CONVERSATION_ID",
  SELECTED_PROPERTY_ID = "property",
  SELECTED_MESSAGE_ID = "SELECTED_MESSAGE_ID",
  PROFILE_ID_SEARCH_PARAM_NAME = "PROFILE_ID",
  SERVICE_CONFIG_ID_SEARCH_PARAM_NAME = "SERVICE_CONFIG_ID",
  CREATE_SERVICE_CONFIG_PARAM = "CREATE_SERVICE_CONFIG",
  DEPENDENCY_GROUP_ID_SEARCH_PARAM_NAME = "DEPENDENCY_GROUP_ID",
  CREATE_DEPENDENCY_GROUP_PARAM = "CREATE_DPENDENCY_GROUP",
  DEFAULT_SERVICE_ID_SEARCH_PARAM_NAME = "DEFAULT_SERVICE_ID",
  CREATE_DEFAULT_SERVICE_PARAM = "CREATE_DEFAULT_SERVICE"
}

//Should not be used without the wrapper.
export const useSelectId = ({ fieldName }: { fieldName: Params }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const setSelectId = useCallback(
    (selectId?: string, additionalUrlParams?: URLSearchParams) => {
      const newSearchParams = new URLSearchParams(searchParams);

      additionalUrlParams?.forEach((value, key) => {
        if (value) {
          newSearchParams.set(key, value);
        } else {
          newSearchParams.delete(key);
        }
      });

      if (selectId) {
        dispatch(addDrawerItem(fieldName));
        newSearchParams.set(fieldName, selectId);
      } else {
        dispatch(removeDrawerItem(fieldName));
        newSearchParams.delete(fieldName);
      }

      navigate({
        pathname: location.pathname,
        search: "?" + newSearchParams.toString()
      });
    },
    [location.pathname, navigate, fieldName, searchParams, dispatch]
  );

  const selectedId = useMemo(() => {
    const searchParamsObj = new URLSearchParams(searchParams);
    return searchParamsObj.get(fieldName);
  }, [searchParams, fieldName]);

  return {
    setSelectId,
    selectedId
  };
};
