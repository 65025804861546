import * as React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Order } from "../../types/pageable";
import { ReservationTableDto } from "../../domain/reservation-table-dto";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Box, Grid2, useTheme } from "@mui/material";
import { Conversation } from "../../messaging/domain/conversation";
import { Task } from "../../features/tasks/domain/task";
import {
  Profiles,
  ReservationsForProfile,
  TransactionsForProfile
} from "src/features/profiles/types/profile";
import { DoorAccessLog } from "../../domain/door-access-log";

// Enum to be used in a justify-content prop
export enum AlignmentTableCell {
  CENTER = "center",
  RIGHT = "right",
  LEFT = "left"
}

export interface HeadCell<T> {
  id: keyof T;
  label: string;
  subLabel?: string;
  disableSort?: boolean;
  alignment?: AlignmentTableCell;
}

interface EnhancedTableProps<T> {
  headCells: Array<HeadCell<T>>;
  onRequestSort: (event: React.MouseEvent<unknown>, field: keyof T) => void;
  order: Order;
  orderBy: keyof T;
  hideRedundantCells?: boolean;
}

type DataType =
  | ReservationTableDto
  | Task
  | Conversation
  | Profiles
  | ReservationsForProfile
  | TransactionsForProfile
  | DoorAccessLog;

export const EnhancedTableHead: <T extends DataType>(
  p: EnhancedTableProps<T>
) => React.ReactElement<EnhancedTableProps<T>> = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
  hideRedundantCells
}) => {
  const createSortHandler =
    <T extends DataType>(field: keyof T) =>
    (event: React.MouseEvent<unknown>) => {
      // @ts-ignore
      onRequestSort(event, field);
    };
  const { t } = useTranslationWrapper();
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow>
        {!hideRedundantCells && <TableCell width="16px" />}
        {headCells.map((headCell) => (
          <TableCell
            key={`${String(headCell.id)}-head`}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ pr: 2 }}
          >
            {headCell.disableSort ? (
              <ParagraphSmall display="flex" gap={0.5}>
                {t(headCell.label)}
                {headCell.subLabel && (
                  <ParagraphSmall sx={{ color: theme.palette.text.secondary }}>
                    ({t(headCell.subLabel)})
                  </ParagraphSmall>
                )}
              </ParagraphSmall>
            ) : (
              <Grid2
                container
                sx={{
                  justifyContent: headCell.alignment ? headCell.alignment : AlignmentTableCell.LEFT
                }}
              >
                <Grid2>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    <Box component="span">{t(headCell.label)}</Box>
                    {headCell.subLabel && (
                      <Box component="span" sx={{ ml: 0.5 }}>
                        <Box component="span" sx={{ color: theme.palette.text.secondary }}>
                          ({t(headCell.subLabel)})
                        </Box>
                      </Box>
                    )}
                  </TableSortLabel>
                </Grid2>
              </Grid2>
            )}
          </TableCell>
        ))}
        {!hideRedundantCells && <TableCell />}
        {!hideRedundantCells && <TableCell />}
      </TableRow>
    </TableHead>
  );
};
