import {
  AccessibleDoor,
  AllowedActionEnum,
  GuestPreferences,
  ReservationConditionEnum as ReservationConditionEnumCommon,
  SentNotifications
} from "../../domain/reservation-table-dto";
import { OverviewDetailsQuery as GetOverviewDetailsQuery } from "../queries/GetReservationDetails.generated";
import { MagicFile } from "../../domain/magic-file";
import { Person, PersonGender } from "../../domain/person";
import {
  BackofficeKeyCard,
  Gender,
  MemberShipClassEnum,
  ReservationAction,
  ReservationCondition,
  ReservationStatus,
  ServiceCodeEnum,
  UnitState
} from "../generated/graphql";
import { ReservationStatus as ReservationStatusCommon } from "../../domain/reservation-status";
import { UnitCondition as UnitConditionCommon } from "../../domain/Unit";
import { ReservationDetailsDTO } from "../../store/endpoints/reservation-table.endpoints";
import { Service } from "../../domain/service";
import { AdditionalServicesCodes } from "../../domain/additional-services-codes";
import { transformFullPrice } from "./transform-utils";
import { mapIdCheckStatusV2ToCommonIdCheckStatus } from "./transform-reservation-table-overview";
import { transformFolioToCommon } from "./transform-folio";

interface PreferenceV2 {
  pmsId: string;
  pmsPreferenceCategoryId: string;
  name: string;
  description?: string | null;
  pmsPropertyId?: string | null | undefined;
}

interface CategoryV2 {
  pmsId: string;
  name: string;
  description?: string | null;
  preferences: PreferenceV2[];
}

export const transformReservationV1ToCommon = (
  reservation: ReservationDetailsDTO | undefined //TODO: We should create new interface for v1 because v1 api dto does not contain displayId
): ReservationDetailsDTO | undefined => {
  return reservation
    ? {
        ...reservation,
        displayId: reservation?.id,
        notes: {
          previousVisits: reservation?.notes?.previousVisits,
          guestComment: reservation?.notes?.guestComment ?? [],
          bookerComment: reservation?.notes?.bookerComment ?? [],
          comment: reservation?.notes?.comment ?? [],
          bookingComment: reservation?.notes?.bookingComment ?? [],
          rateBreakdownComment: reservation?.notes?.rateBreakdownComment ?? [],
          beddingComment: reservation?.notes?.beddingComment ?? []
        },
        services: reservation?.services ?? [],
        travelBuddies: reservation?.travelBuddies ?? []
      }
    : undefined;
};
export type ReservationTypeV2 = GetOverviewDetailsQuery["OverviewDetails"];
type ExcludedOverviewDetailsV2 = Exclude<
  GetOverviewDetailsQuery["OverviewDetails"],
  undefined | null
>;
export const transformReservationDetailsV2ToCommonReservationDetails = (
  arg: ReservationTypeV2
): ReservationDetailsDTO | undefined => {
  return arg
    ? {
        id: arg?.pmsReservationId ?? "",
        displayId: arg?.extraPmsSimpleProperties?.confirmationNumber ?? arg?.pmsReservationId ?? "",
        accessibleDoors:
          arg?.accessibleDoors?.map(transformV2AccessibleDoorsToCommonAcessibleDoors) ?? [],
        keyCards: arg?.keyCards?.map(transformV2KeyCardsToCommonAcessibleDoors) ?? [],
        magicToken: arg?.magicToken ?? "",
        magicId: arg?.magicId ?? "",
        magicLink: arg?.magicLink ?? "",
        bookingMagicLink: arg?.bookingMagicLink ?? "",
        createdAt: arg?.createdAt ?? "",
        checkoutOnAr: arg?.checkoutOnAr ?? false,
        files: arg?.files?.map(transformV2FilesToCommonFiles) ?? [],
        booker: {
          address: {
            addressLine1: arg?.booker?.address?.addressLine1 ?? "",
            addressLine2: arg?.booker?.address?.addressLine2,
            postalCode: arg?.booker?.address?.postalCode ?? "",
            city: arg?.booker?.address?.city ?? "",
            countryCode: arg?.booker?.address?.countryCode ?? "",
            region: arg?.booker?.address?.region ?? ""
          },
          isBlacklisted: arg.booker?.userProfile?.blacklisted ?? false,
          birthdate: arg?.booker?.birthdate,
          email: arg?.booker?.email ?? "",
          firstName: arg?.booker?.firstName ?? "",
          gender: mapGuestGenderV2ToCommonGuestGender(arg?.booker?.gender ?? undefined),
          guestPreferences: transformUserPreferencesV2ToUserPreferencesV1(
            arg?.booker?.userProfile?.preferences ?? []
          ),
          guestPreferencesText: "",
          identificationNumber: arg?.booker?.identificationDocumentNumber ?? "",
          identificationType: arg?.booker?.identificationDocumentType ?? "",
          isRecurring: arg?.booker?.userProfile?.isRecurring ?? false,
          lastName: arg?.booker?.lastName ?? "",
          nationalityCountryCode: arg?.booker?.nationality ?? "",
          phone: transformPhone(arg?.booker?.phone ?? ""),
          preferredCommunicationChannel: arg?.booker?.preferredCommunicationChannel ?? "",
          preferredLanguage: arg?.booker?.preferredLanguage ?? "",
          userProfileId: arg?.booker?.userProfileId ?? undefined,
          hasAccount: !!arg?.booker?.userProfile?.userAccountUuid
        },
        primaryGuest: {
          address: {
            addressLine1: arg?.primaryGuest?.address?.addressLine1 ?? "",
            addressLine2: arg?.primaryGuest?.address?.addressLine2,
            postalCode: arg?.primaryGuest?.address?.postalCode ?? "",
            city: arg?.primaryGuest?.address?.city ?? "",
            countryCode: arg?.primaryGuest?.address?.countryCode ?? "",
            region: arg?.primaryGuest?.address?.region ?? ""
          },
          isBlacklisted: arg.primaryGuest?.userProfile?.blacklisted ?? false,
          birthdate: arg?.primaryGuest?.birthdate ?? "",
          email: arg?.primaryGuest?.email ?? "",
          firstName: arg?.primaryGuest?.firstName ?? "",
          gender: mapGuestGenderV2ToCommonGuestGender(arg?.primaryGuest?.gender ?? undefined),
          guestPreferences: transformUserPreferencesV2ToUserPreferencesV1(
            arg?.primaryGuest?.userProfile?.preferences ?? []
          ),
          guestNotes: arg.primaryGuest?.userProfile?.notes ?? [],
          guestPreferencesText: "",
          identificationNumber: arg?.primaryGuest?.identificationDocumentNumber ?? "",
          identificationType: arg?.primaryGuest?.identificationDocumentType ?? "",
          isRecurring: arg?.primaryGuest?.userProfile?.isRecurring ?? false,
          lastName: arg?.primaryGuest?.lastName ?? "",
          nationalityCountryCode: arg?.primaryGuest?.nationality ?? "",
          phone: transformPhone(arg?.primaryGuest?.phone ?? ""),
          preferredCommunicationChannel: arg?.primaryGuest?.preferredCommunicationChannel ?? "",
          preferredLanguage: arg?.primaryGuest?.preferredLanguage ?? "",
          userProfileId: arg?.primaryGuest?.userProfileId ?? undefined,
          hasAccount: !!arg?.primaryGuest?.userProfile?.userAccountUuid,
          vipCode: arg?.primaryGuest?.vipCode ?? "",
          eClubMembershipNumber:
            arg?.primaryGuest?.membershipAccounts?.find((account) => {
              return account?.membershipClass === MemberShipClassEnum.LoyaltyProgram;
            })?.membershipId ?? "",
          id: arg?.primaryGuest?.pmsId ?? "",
          emergencyEvacuationHelpNeeded: arg?.primaryGuest?.emergencyEvacuationHelpNeeded ?? false,
          emergencyEvacuationHelpNotes: arg.primaryGuest?.emergencyEvacuationHelpNotes ?? "",
          enrolledInLoyaltyProgram: arg?.primaryGuest?.enrolledInLoyaltyProgram ?? false,
          frequentFlyerPrograms: arg?.primaryGuest?.membershipAccounts.filter(
            (item) => item?.membershipClass === MemberShipClassEnum.FrequentFlyer
          ),
          licensePlate: arg?.primaryGuest?.licensePlate ?? ""
        },
        preferredCleaningDays: arg?.preferredCleaningDays ?? null,
        adultsCount: arg?.adultsCount,
        allowedActions:
          arg?.allowedActions?.map(transformV2AllowedActionsToCommonAllowedActions) ?? [],
        childrenCount: arg?.childrenCount,
        childrenAges: arg?.childrenAges ?? [],
        reservationStatus: mapReservationStateV2ToCommonReservationState(arg?.reservationStatus),
        notes: {
          previousVisits: arg?.notes?.previousVisits ?? 0,
          guestComment: arg?.notes?.guestComment ?? [],
          bookerComment: arg?.notes?.bookerComment ?? [],
          comment: arg?.notes?.comment ?? [],
          bookingComment: arg?.notes?.bookingComment ?? [],
          rateBreakdownComment: arg?.notes?.rateBreakdownComment ?? [],
          beddingComment: arg?.notes?.beddingComment ?? []
        },
        arrival: arg?.arrival,
        departure: arg?.departure,
        unitCleanOnCheckin: !!arg?.unitCleanOnCheckin,
        idCheckStatus: mapIdCheckStatusV2ToCommonIdCheckStatus(arg?.idCheckStatus),
        unit: {
          unitId: arg?.unit?.pmsUnitId ?? "",
          unitGroupId: arg?.unit?.pmsUnitGroupId ?? "",
          name: arg?.unit?.name ?? "",
          condition: mapUnitConditionV2ToCommonUnitCondition(arg?.unit?.condition ?? undefined),
          privacyMode: !!arg?.unit?.privacyMode,
          privacyModeLastChangeTimestamp: arg?.unit?.privacyModeLastChangeTimestamp ?? "",
          occupied: !!arg?.unit?.occupied
        },
        bookedUnitGroupId: arg?.bookedPmsUnitGroupId ?? "",
        reservationCondition: mapReservationConditionV2ToCommonReservationCondition(
          arg?.reservationCondition
        ),
        reservationConditionReasons: arg?.reservationConditionReasons ?? {},
        reservationIsPaid: !!arg?.reservationIsPaid,
        bookingChannel: arg?.bookingChannel ?? "",
        propertyId: arg?.pmsPropertyId ?? "",
        lastConfirmedPage: arg?.lastConfirmedPage ?? "",
        lastConfirmedCheckoutPage: arg.lastConfirmedCheckoutPage ?? "",
        secondScreenCheckpoint: arg?.secondScreenCheckpoint ?? "",
        completed: !!arg?.completed,
        travelBuddies: arg?.travelBuddies?.map(transformV2TravelBuddiesToCommonTravelBuddies) ?? [],
        checkInTime: arg?.checkinTime,
        checkOutTime: arg?.checkoutTime,
        estimatedArrivalTime: arg?.estimatedArrivalTime,
        estimatedDepartureTime: arg?.estimatedDepartureTime,
        guestPreferences: {},
        primaryGuestSameAsBooker: !!arg?.primaryGuestSameAsBooker,
        sentNotifications:
          arg?.sentNotifications?.map(transformV2SentNotificationToCommonSentNotification) ?? [],
        firstName: arg?.primaryGuest?.firstName ?? "",
        lastName: arg?.primaryGuest?.lastName ?? "",
        //
        balance: transformFullPrice(arg?.balance),
        canCheckout: arg?.canCheckout,
        services: arg?.bookedServicesOverview.map(transformV2ServicesToCommonServices) ?? [],
        maxCompanions: arg?.maxCompanions ?? 0,
        contractualCheckInTime: arg?.contractualCheckInTime ?? "",
        rateBreakDown:
          arg?.groupedRateBreakdown?.rateSpliceTypeTuples?.map((item) => ({
            id: item.type ?? "",
            breakDownItems:
              item.pmsIdToRateSplicesTuples?.map((itemTuples) => ({
                id: itemTuples.pmsId ?? "",
                items:
                  itemTuples.rateSplices?.map((itemSplices) => ({
                    price: transformFullPrice(itemSplices.grossPrice),
                    name: itemSplices.displayName ?? "",
                    quantity: itemSplices.quantity ?? 0,
                    type: itemSplices.type ?? ""
                  })) ?? []
              })) ?? []
          })) ?? [],
        folios: arg.folios?.map(transformFolioToCommon) ?? [],
        checkedInBy: arg?.checkedInBy ?? undefined,
        checkedOutBy: arg?.checkedOutBy ?? undefined,
        restaurantReservations: arg?.restaurantReservations ?? [],
        originalPmsUnitGroupId:
          arg?.reservationUpsellInformation?.originalPmsUnitGroupId ?? undefined,
        foodAndBeverageOrders: arg?.foodAndBeverageOrders ?? []
      }
    : undefined;
};

type ExtractedOverviewDetails = Exclude<
  GetOverviewDetailsQuery["OverviewDetails"],
  undefined | null
>;

type ExtractedAcessibleDoors = Exclude<
  ExtractedOverviewDetails["accessibleDoors"],
  undefined | null
>;

type ExtractedKeyCards = Exclude<ExtractedOverviewDetails["keyCards"], undefined | null>;

type ExtractSentNotifications = Exclude<
  ExcludedOverviewDetailsV2["sentNotifications"],
  null | undefined
>;

type ExtractSentNotification = Exclude<ExtractSentNotifications, null | undefined>[number];

export const transformV2SentNotificationToCommonSentNotification = (
  sentNotificationsV2: Partial<ExtractSentNotification>
): SentNotifications => {
  return {
    channel: sentNotificationsV2?.channel ?? "",
    contentLink: sentNotificationsV2?.contentLink ?? "",
    conversationId: sentNotificationsV2?.conversationId ?? "",
    id: sentNotificationsV2?.id ?? "",
    language: sentNotificationsV2?.language ?? "",
    recipient: sentNotificationsV2?.recipient ?? "",
    status: sentNotificationsV2?.status ?? "",
    template: sentNotificationsV2?.template ?? "",
    timestamp: sentNotificationsV2?.lastUpdateTimestamp ?? "",
    magicId: sentNotificationsV2?.magicId ?? ""
  };
};

type ExtractedAcessibleDoor = ExtractedAcessibleDoors[number];

const transformV2AccessibleDoorsToCommonAcessibleDoors = (
  accessibleDoorsV2: Partial<ExtractedAcessibleDoor>
): AccessibleDoor => {
  return {
    id: accessibleDoorsV2?.id ?? "",
    title: accessibleDoorsV2?.title ?? "",
    is_general: !!accessibleDoorsV2?.isGeneral,
    pin: accessibleDoorsV2?.pin ?? ""
  };
};

type ExtractedKeyCard = ExtractedKeyCards[number];

const transformV2KeyCardsToCommonAcessibleDoors = (
  keyCardsV2: Partial<ExtractedKeyCard>
): BackofficeKeyCard => {
  return {
    id: keyCardsV2?.id ?? "",
    createdAt: keyCardsV2?.createdAt ?? "",
    isCopy: !!keyCardsV2?.isCopy,
    requestor: {
      principal: keyCardsV2?.requestor?.principal ?? "",
      role: keyCardsV2?.requestor?.role ?? "",
      travelBuddyGuestId: keyCardsV2?.requestor?.travelBuddyGuestId ?? ""
    }
  };
};

type ExtractedFiles = Exclude<ExcludedOverviewDetailsV2["files"], undefined | null>[number];

export const transformV2FilesToCommonFiles = (filesV2: Partial<ExtractedFiles>): MagicFile => {
  return {
    fileName: filesV2?.fileName ?? "",
    contentType: filesV2?.contentType ?? "",
    metaData: {
      ownerId: filesV2?.metaData?.ownerId ?? "",
      magicFileType: filesV2?.metaData?.magicFileType
    }
  };
};

type ExtractTravelBuddies = Exclude<
  ExcludedOverviewDetailsV2["travelBuddies"],
  null | undefined
>[number];

const transformV2TravelBuddiesToCommonTravelBuddies = (
  travelBuddiesV2: Partial<ExtractTravelBuddies>
): Person => {
  return {
    address: {
      addressLine1: travelBuddiesV2?.address?.addressLine1 ?? "",
      addressLine2: travelBuddiesV2?.address?.addressLine2,
      postalCode: travelBuddiesV2?.address?.postalCode ?? "",
      city: travelBuddiesV2?.address?.city ?? "",
      countryCode: travelBuddiesV2?.address?.countryCode ?? ""
    },
    birthdate: travelBuddiesV2?.birthdate,
    email: travelBuddiesV2?.email ?? "",
    firstName: travelBuddiesV2?.firstName ?? "",
    gender: mapGuestGenderV2ToCommonGuestGender(travelBuddiesV2?.gender),
    guestPreferences: {},
    guestPreferencesText: "",
    identificationNumber: travelBuddiesV2?.identificationDocumentNumber ?? "",
    identificationType: travelBuddiesV2?.identificationDocumentType ?? "",
    isRecurring: !!travelBuddiesV2?.userProfile?.isRecurring,
    lastName: travelBuddiesV2?.lastName ?? "",
    nationalityCountryCode: travelBuddiesV2?.nationality ?? "",
    phone: transformPhone(travelBuddiesV2?.phone ?? ""),
    preferredCommunicationChannel: travelBuddiesV2?.preferredCommunicationChannel ?? "",
    preferredLanguage: travelBuddiesV2?.preferredLanguage ?? "",
    userProfileId: travelBuddiesV2?.userProfileId ?? undefined,
    hasAccount: !!travelBuddiesV2?.userProfile?.userAccountUuid,
    id: travelBuddiesV2.pmsId ?? "",
    isBlacklisted: travelBuddiesV2.userProfile?.blacklisted ?? false
  };
};
type ExtractAllowedActions = Exclude<
  ExcludedOverviewDetailsV2["allowedActions"],
  null | undefined
>[number];

export const transformV2AllowedActionsToCommonAllowedActions = (
  allowedActionsV2?: Partial<ExtractAllowedActions>
): AllowedActionEnum => {
  switch (allowedActionsV2) {
    case ReservationAction.ChangeIdCheckStatus:
      return AllowedActionEnum.CHANGE_ID_CHECK_STATUS;
    case ReservationAction.AssignUnit:
      return AllowedActionEnum.ASSIGN_UNIT;
    case ReservationAction.ChangeStatusToCanceled:
      return AllowedActionEnum.CHANGE_STATUS_TO_CANCELED;
    case ReservationAction.ChangeStatusToCheckedOut:
      return AllowedActionEnum.CHANGE_STATUS_TO_CHECKED_OUT;
    case ReservationAction.ChangeStatusToInHouse:
      return AllowedActionEnum.CHANGE_STATUS_TO_IN_HOUSE;
    case ReservationAction.ChangeUnitConditionToClean:
      return AllowedActionEnum.CHANGE_UNIT_CONDITION_TO_CLEAN;
    case ReservationAction.ChangeUnitConditionToCleanToBeInspected:
      return AllowedActionEnum.CHANGE_UNIT_CONDITION_TO_CLEAN_TO_BE_INSPECTED;
    case ReservationAction.ValidateReservation:
      return AllowedActionEnum.VALIDATE_RESERVATION;
    case ReservationAction.ChangeUnitConditionToDirty:
      return AllowedActionEnum.CHANGE_UNIT_CONDITION_TO_DIRTY;
    case ReservationAction.JumpToBooking:
      return AllowedActionEnum.JUMP_TO_BOOKING;
    case ReservationAction.JumpToReservation:
      return AllowedActionEnum.JUMP_TO_RESERVATION;
    case ReservationAction.EncodeKey:
      return AllowedActionEnum.ENCODE_KEY;
    case ReservationAction.ResendNotification:
      return AllowedActionEnum.RESEND_NOTIFICATION;
    case ReservationAction.ResendGuestflowLink:
      return AllowedActionEnum.RESEND_GUESTFLOW_LINK;
    case ReservationAction.CopyGuestflowLink:
      return AllowedActionEnum.COPY_GUESTFLOW_LINK;
    case ReservationAction.ManualCheckin:
      return AllowedActionEnum.MANUAL_CHECKIN;
    case ReservationAction.ManualCheckout:
      return AllowedActionEnum.MANUAL_CHECKOUT;
    case ReservationAction.SendCustomNotification:
      return AllowedActionEnum.SEND_CUSTOM_NOTIFICATION;
    default:
      console.warn("Not handled reservation action ", allowedActionsV2);
      return AllowedActionEnum.UNKNOWN;
  }
};

type ExtractServices = Exclude<
  ExcludedOverviewDetailsV2["bookedServicesOverview"],
  undefined | null
>[number];

const transformV2ServicesToCommonServices = (servicesV2: Partial<ExtractServices>): Service => {
  return {
    price: transformFullPrice(servicesV2?.service?.totalAmount),
    name: servicesV2?.service?.name ?? "",
    quantity: servicesV2?.dates?.length ?? 0,
    dates: servicesV2.dates?.map((date) => date.serviceDate) ?? [],
    createdAt: "",
    updatedAt: "",
    code: servicesV2?.service?.magicServiceCodeEnum
      ? transformServiceCodeV2ToCommonServiceCode(servicesV2?.service?.magicServiceCodeEnum)
      : ""
  };
};

// A lot of types missing in our V1 AdditionalServicesCodes compared to the V2?
const transformServiceCodeV2ToCommonServiceCode = (
  code?: ServiceCodeEnum | null
): AdditionalServicesCodes => {
  switch (code) {
    case ServiceCodeEnum?.BabyBed:
      return AdditionalServicesCodes?.BABY_BED;
    case ServiceCodeEnum?.FreeCleaning:
      return AdditionalServicesCodes?.FREE_CLEANING;
    case ServiceCodeEnum?.DonateBreakfast:
      return AdditionalServicesCodes?.DONATE_BREAKFAST;
    case ServiceCodeEnum?.PaidCleaning:
      return AdditionalServicesCodes?.PAID_CLEANING;
    case ServiceCodeEnum?.FreeBreakfast:
      return AdditionalServicesCodes?.FREE_BREAKFAST;
    case ServiceCodeEnum?.Storage:
      return AdditionalServicesCodes?.STORAGE;
    case ServiceCodeEnum?.EarlyCheckin:
      return AdditionalServicesCodes?.EARLY_CHECKIN;
    case ServiceCodeEnum?.LateCheckout:
      return AdditionalServicesCodes?.LATE_CHECKOUT;
    default:
      return AdditionalServicesCodes.UNKNOWN;
  }
};

export const mapReservationStateV2ToCommonReservationState = (
  status?: ReservationStatus | undefined | null
): ReservationStatusCommon => {
  switch (status) {
    case ReservationStatus?.CheckedOut:
      return ReservationStatusCommon?.CHECKED_OUT;
    case ReservationStatus?.Confirmed:
      return ReservationStatusCommon?.CONFIRMED;
    case ReservationStatus?.InHouse:
      return ReservationStatusCommon?.IN_HOUSE;
    case ReservationStatus?.NoShow:
      return ReservationStatusCommon?.NO_SHOW;
    default:
      return ReservationStatusCommon?.DELETED;
  }
};

const mapUnitConditionV2ToCommonUnitCondition = (
  condition?: UnitState | null | undefined
): UnitConditionCommon => {
  switch (condition) {
    case UnitState?.Dirty:
      return UnitConditionCommon?.DIRTY;
    case UnitState?.CleanToBeInspected:
      return UnitConditionCommon?.CLEAN_TO_BE_INSPECTED;
    case UnitState?.Clean:
      return UnitConditionCommon?.CLEAN;
    default:
      return UnitConditionCommon?.UNKNOWN;
  }
};

const mapReservationConditionV2ToCommonReservationCondition = (
  reservationCondition?: ReservationCondition | null | undefined
): ReservationConditionEnumCommon => {
  switch (reservationCondition) {
    case ReservationCondition.Critical:
      return ReservationConditionEnumCommon.CRITICAL;
    case ReservationCondition.Minor:
      return ReservationConditionEnumCommon.MINOR;
    case ReservationCondition.Ok:
      return ReservationConditionEnumCommon.OK;
    default:
      return ReservationConditionEnumCommon.UNKNOWN;
  }
};

export const mapGuestGenderV2ToCommonGuestGender = (
  gender: Gender | undefined | null
): PersonGender | undefined => {
  switch (gender) {
    case Gender.Male:
      return PersonGender.MALE;
    case Gender.Female:
      return PersonGender.FEMALE;
    case Gender.Other:
      return PersonGender.OTHER;
    default:
      return undefined;
  }
};

export const transformPhone = (phone: string): string => {
  if (phone.startsWith("00")) {
    return "+" + phone.substring(2);
  } else {
    return phone;
  }
};

export interface TransFormPreferencesResult {
  [key: string]: string[];
}

export const transformPreferencesV2ToPreferencesV1 = (
  preferences: CategoryV2[] | undefined
): TransFormPreferencesResult | undefined => {
  const result: TransFormPreferencesResult = {};

  if (preferences) {
    preferences.forEach((preference) => {
      result[preference.pmsId] = preference.preferences.map((pref) => pref.pmsId);
    });
  }

  return result;
};

const transformUserPreferencesV2ToUserPreferencesV1 = (preferences: PreferenceV2[]) => {
  const transformedPreferences: GuestPreferences = {};

  if (preferences) {
    preferences.forEach((preference) => {
      transformedPreferences[preference.pmsId] = preference.description;
    });
  }
  return transformedPreferences;
};
